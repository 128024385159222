<template>
  <section class="layout-controller">
    <NuxtImg v-if="backgroundSrc" :src="backgroundSrc" class="layout-controller__bg" />
    <div class="layout-controller__container">
      <MainPageEventTimer :date-time="eventStore.eventTimerUnixTime" theme="dark" sharp-top />
      <NuxtImg
        v-if="imageSrcComputed"
        :alt="eventStore.eventFullName"
        :width="changeXL(800, changeMD(424, 360))"
        :height="changeXL(286, changeMD(184, 164))"
        class="layout-controller__logo"
        :src="imageSrcComputed"
      />
      <div class="layout-controller__event-info">
        <MainPageHowItWorksPopup
          :button="eventStore.eventButtonGradient"
          :event-coin-icon="eventStore.coinIcon"
          :coin-cases-name="coinTitle?.text ?? 'Coin cases'"
          :logo="popupLogoSrc"
          :event-full-name="eventTitle?.text ?? eventStore.eventFullName"
          :popup-data="eventStore.popup"
        >
        </MainPageHowItWorksPopup>

        <p class="layout-controller__subtext">
          {{ eventStore.eventSubtitle }}
        </p>
      </div>
    </div>
    <header v-if="eventStore.activeTabs.length" class="layout-controller__tabs-container">
      <div class="layout-controller__tabs">
        <MainPageHeaderTab
          v-for="(tabName, index) in eventStore.activeTabs"
          :key="index"
          :data="MainPageActiveTabsPreset($t)[tabName]"
          :text="MainPageActiveTabsPreset($t)[tabName].labelText"
          :coin-icon-src="eventStore.coinIcon"
          v-bind="MainPageActiveTabsPreset($t)[tabName].isDailyCases && giftsCount"
          @click="MainPageActiveTabsPreset($t)[tabName].amplitude?.()"
        />
      </div>
    </header>
  </section>
</template>

<script setup lang="ts">
import { Breakpoints } from '~/constants/media.constants';
import { useEventStore } from '~/store/event/event.store';
import { MainPageActiveTabsPreset } from '~/features/mainPage/presets/HeaderTab.presets';
import { useUserStore } from '~/store/user/user.store';
import { useEventCasesStore } from '~/features/mainPage/store/eventCases.store';
import { useCoinCasesStore } from '~/features/mainPage/store/coinCases.store';

const viewport = useViewport();
const eventStore = useEventStore();
const userStore = useUserStore();
const eventCasesStore = useEventCasesStore();
const { titleOptions: eventTitle } = storeToRefs(eventCasesStore);
const coinCasesStore = useCoinCasesStore();
const { titleOptions: coinTitle } = storeToRefs(coinCasesStore);

const changeXXL = GlobalUtils.Media.changeByMedia('xxl');
const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const giftsCount = computed(() => {
  if (userStore.user?.availableCases?.length) {
    return {
      quantity: userStore.user.availableCases.length,
    };
  }

  if (userStore.user?.nearestCaseTime) {
    return {
      time: userStore.user.nearestCaseTime * 1000,
    };
  }

  return {};
});

const backgroundSrc = computed(() => {
  if (changeXXL(true, false)) return eventStore.imageBackground.xxl;
  if (changeXL(true, false)) return eventStore.imageBackground.xl;
  if (changeMD(true, false)) return eventStore.imageBackground.m;
  return eventStore.imageBackground.s;
});

const imageSrcComputed = computed(() => {
  if (viewport.isGreaterOrEquals(Breakpoints.xl)) return eventStore.imageLogo.xl;
  if (viewport.isGreaterOrEquals(Breakpoints.md)) return eventStore.imageLogo.m;
  return eventStore.imageLogo.s;
});

const popupLogoSrc = computed(() => {
  if (viewport.isGreaterOrEquals(Breakpoints.xl)) return eventStore.imageLogo.s;
  return eventStore.imageLogo.case;
});
</script>

<style scoped lang="scss" src="./LayoutController.scss" />
